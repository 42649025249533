import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="druid"></a>DRUID</h3>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG34_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Neutral good, lawful
neutral, neutral, chaotic neutral, or neutral evil.</p>
    <p><b>Hit Die</b>: d8.</p>
    <h6>Class Skills</h6>
    <p className="initial">The druid&rsquo;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
(Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle
Animal</a> (Cha), <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a> (Wis), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(nature) (Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
(Dex), <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> (Int), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
(Wis), <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> (Wis), and <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str).</p>
    <p><b>Skill Points at 1st Level</b>: (4 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 4 + Int modifier.</p>
    <p><a id="table-the-druid"></a><b>Table: The Druid</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "34%"
          }}>Special</th>
          <th colSpan="10" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "3%"
          }}>0</th>
          <th style={{
            "width": "3%"
          }}>1st</th>
          <th style={{
            "width": "3%"
          }}>2nd</th>
          <th style={{
            "width": "3%"
          }}>3rd</th>
          <th style={{
            "width": "3%"
          }}>4th</th>
          <th style={{
            "width": "3%"
          }}>5th</th>
          <th style={{
            "width": "3%"
          }}>6th</th>
          <th style={{
            "width": "3%"
          }}>7th</th>
          <th style={{
            "width": "3%"
          }}>8th</th>
          <th style={{
            "width": "3%"
          }}>9th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>+2</td>
          <td>Animal companion, nature sense, wild empathy</td>
          <td>3</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+0</td>
          <td>+3</td>
          <td>Woodland stride</td>
          <td>4</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+1</td>
          <td>+3</td>
          <td>Trackless step</td>
          <td>4</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>Resist nature&rsquo;s lure</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>Wild shape (1/day)</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>Wild shape (2/day)</td>
          <td>5</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>Wild shape (3/day)</td>
          <td>6</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>Wild shape (Large)</td>
          <td>6</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>Venom immunity</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+7</td>
          <td>+3</td>
          <td>+7</td>
          <td>Wild shape (4/day)</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+7</td>
          <td>+3</td>
          <td>+7</td>
          <td>Wild shape (Tiny)</td>
          <td>6</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>+8</td>
          <td>Wild shape (plant)</td>
          <td>6</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>+8</td>
          <td>A thousand faces</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+9</td>
          <td>+4</td>
          <td>+9</td>
          <td>Wild shape (5/day)</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+9</td>
          <td>+5</td>
          <td>+9</td>
          <td>Timeless body, wild shape (Huge)</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+10</td>
          <td>Wild shape (elemental 1/day)</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+11</td>
          <td>Wild shape (6/day, elemental 2/day)</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">Wild shape (elemental 3/day, Huge
elemental)</td>
          <td className="last-row">6</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="druid-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
druid.</p>
    <p><b>Weapon and Armor Proficiency</b>: Druids are proficient
with the following weapons: club, dagger, dart, quarterstaff, scimitar,
sickle, shortspear, sling, and spear. They are also proficient with all
natural attacks (claw, bite, and so forth) of any form they assume with
wild shape (see below).</p>
    <p>Druids are proficient with light and medium armor but are
prohibited from wearing metal armor; thus, they may wear only padded,
leather, or hide armor. (A druid may also wear wooden armor that has
been altered by the ironwood spell so that it functions as though it
were steel. See the ironwood spell description) Druids are proficient
with shields (except tower shields) but must use only wooden ones.</p>
    <p>A druid who wears prohibited armor or carries a prohibited
shield is unable to cast druid spells or use any of her supernatural or
spell-like class abilities while doing so and for 24 hours thereafter.</p>
    <p><b>Spells</b>: A druid casts divine spells, which are drawn
from the <a style={{
        "color": "#579eb6"
      }} href="druidSpells.html">druid spell list</a>. Her alignment may restrict her from casting
certain spells opposed to her moral or ethical beliefs; see Chaotic,
Evil, Good, and Lawful Spells, below. A druid must choose and prepare
her spells in advance (see below).</p>
    <p>To prepare or cast a spell, the druid must have a Wisdom score
equal to at least 10 + the spell level. The Difficulty Class for a
saving throw against a druid&rsquo;s spell is 10 + the spell level + the
druid&rsquo;s Wisdom modifier.</p>
    <p>Like other spellcasters, a druid can cast only a certain
number of spells of each spell level per day. Her base daily spell
allotment is given on Table: The Druid. In addition, she receives bonus
spells per day if she has a high Wisdom score. She does not have access
to any domain spells or granted powers, as a cleric does.</p>
    <p>A druid prepares and casts spells the way a cleric does,
though she cannot lose a prepared spell to cast a cure spell in its
place (but see Spontaneous Casting, below). A druid may prepare and
cast any spell on the druid spell list, provided that she can cast
spells of that level, but she must choose which spells to prepare
during her daily meditation.</p>
    <p><a id="druid-spontaneous-casting"></a><b>Spontaneous Casting</b>: A druid can channel stored spell
energy into summoning spells that she hasn&rsquo;t prepared ahead of time.
She can &ldquo;lose&rdquo; a prepared spell in order to cast any summon nature&rsquo;s
ally spell of the same level or lower. Chaotic, Evil, Good, and Lawful
Spells: A druid can&rsquo;t cast spells of an alignment opposed to her own or
her deity&rsquo;s (if she has one). Spells associated with particular
alignments are indicated by the chaos, evil, good, and law descriptors
in their spell descriptions.</p>
    <p><a id="druid-bonus-languages"></a><b>Bonus Languages</b>: A druid&rsquo;s bonus language options
include Sylvan, the language of woodland creatures. This choice is in
addition to the bonus languages available to the character because of
her race.</p>
    <p>A druid also knows Druidic, a secret language known only to
druids, which she learns upon becoming a 1st-level druid. Druidic is a
free language for a druid; that is, she knows it in addition to her
regular allotment of languages and it doesn&rsquo;t take up a language slot.
Druids are forbidden to teach this language to nondruids. Druidic has
its own alphabet.</p>
    <p><a id="druid-animal-companion"></a><b>Animal Companion (Ex)</b>: A druid may begin play with an
animal companion selected from the following list: badger, camel, dire
rat, dog, riding dog, eagle, hawk, horse (light or heavy), owl, pony,
snake (Small or Medium viper), or wolf. If the campaign takes place
wholly or partly in an aquatic environment, the following creatures are
also available: crocodile, porpoise, Medium shark, and squid. This
animal is a loyal companion that accompanies the druid on her
adventures as appropriate for its kind.</p>
    <p>A 1st-level druid&rsquo;s companion is completely typical for its
kind except as noted below. As a druid advances in level, the animal&rsquo;s
power increases as shown on the table. If a druid releases her
companion from service, she may gain a new one by performing a ceremony
requiring 24 uninterrupted hours of prayer. This ceremony can also
replace an animal companion that has perished.</p>
    <p>A druid of 4th level or higher may select from alternative
lists of animals (see below). Should she select an animal companion
from one of these alternative lists, the creature gains abilities as if
the character&rsquo;s druid level were lower than it actually is. Subtract
the value indicated in the appropriate list header from the character&rsquo;s
druid level and compare the result with the druid level entry on the
table to determine the animal companion&rsquo;s powers. (If this adjustment
would reduce the druid&rsquo;s effective level to 0 or lower, she can&rsquo;t have
that animal as a companion.)</p>
    <p><a id="druid-nature-sense"></a><b>Nature Sense (Ex)</b>: A druid gains a +2 bonus on
Knowledge (nature) and Survival checks.</p>
    <p><a id="druid-wild-empathy"></a><b>Wild Empathy (Ex)</b>: A druid can improve the attitude of
an animal. This ability functions just like a <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
check made to
improve the attitude of a person. The druid rolls 1d20 and adds her
druid level and her Charisma modifier to determine the wild empathy
check result. The typical domestic animal has a starting attitude of
indifferent, while wild animals are usually unfriendly.</p>
    <p>To use wild empathy, the druid and the animal must be able to
study each other, which means that they must be within 30 feet of one
another under normal conditions. Generally, influencing an animal in
this way takes 1 minute but, as with influencing people, it might take
more or less time.</p>
    <p>A druid can also use this ability to influence a magical beast
with an Intelligence score of 1 or 2, but she takes a &ndash;4 penalty on the
check.</p>
    <p><a id="druid-woodland-stride"></a><b>Woodland Stride (Ex)</b>: Starting at 2nd level, a druid
may move through any sort of undergrowth (such as natural thorns,
briars, overgrown areas, and similar terrain) at her normal speed and
without taking damage or suffering any other impairment. However,
thorns, briars, and overgrown areas that have been magically
manipulated to impede motion still affect her.</p>
    <p><a id="druid-trackless-step"></a><b>Trackless Step (Ex)</b>: Starting at 3rd level, a druid
leaves no trail in natural surroundings and cannot be tracked. She may
choose to leave a trail if so desired.</p>
    <p><a id="druid-resist-natures-lure"></a><b>Resist Nature&rsquo;s Lure (Ex)</b>: Starting at 4th level, a
druid gains a +4 bonus on saving throws against the spell-like
abilities of fey.</p>
    <p><a id="druid-wild-shape"></a><b>Wild Shape (Su)</b>: At 5th level, a druid gains the
ability to turn herself into any Small or Medium animal and back again
once per day. Her options for new forms include all creatures with the
animal type. This ability functions like the <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#alternate-form">alternate form</a> special ability, except as
noted here. The effect lasts for 1 hour per druid level, or until she
changes back. Changing form (to animal or back) is a standard action
and doesn&rsquo;t provoke an attack of opportunity. Each time you use wild shape,
you regain hit points as if you had rested for a night.</p>
    <p>Any gear worn or carried by the druid melds into the new
form and becomes nonfunctional. When the druid reverts
to her true form, any objects previously melded into the
new form reappear in the same location on her body that
they previously occupied and are once again functional.
Any new items worn in the assumed form fall off and
land at the druid's feet.</p>
    <p>The form chosen must be that of an animal the druid is
familiar with.</p>
    <p>A druid loses her ability to speak while in animal form
because she is limited to the sounds that a normal, untrained animal
can make, but she can communicate normally with other animals of the
same general grouping as her new form. (The normal sound a wild parrot
makes is a squawk, so changing to this form does not permit speech.)</p>
    <p>A druid can use this ability more times per day at 6th, 7th,
10th, 14th, and 18th level, as noted on Table: The Druid. In addition,
she gains the ability to take the shape of a Large animal at 8th level,
a Tiny animal at 11th level, and a Huge animal at 15th level. The new
form&rsquo;s Hit Dice can&rsquo;t exceed the character&rsquo;s druid level.</p>
    <p>At 12th level, a druid becomes able to use wild shape to
change into a plant creature with the same size restrictions as for
animal forms. (A druid can&rsquo;t use this ability to take the form of a
plant that isn&rsquo;t a creature.)</p>
    <p>At 16th level, a druid becomes able to use wild shape to
change into a Small, Medium, or Large elemental (air, earth, fire, or
water) once per day. These elemental forms are in addition to her
normal wild shape usage. In addition to the normal effects of wild
shape, the druid gains all the elemental&rsquo;s extraordinary, supernatural,
and spell-like abilities. She also gains the elemental&rsquo;s feats for as
long as she maintains the wild shape, but she retains her own creature
type.</p>
    <p>At 18th level, a druid becomes able to assume elemental form
twice per day, and at 20th level she can do so three times per day. At
20th level, a druid may use this wild shape ability to change into a
Huge elemental.</p>
    <p><a id="druid-venom-immunity"></a><b>Venom Immunity (Ex)</b>: At 9th level, a druid gains
immunity to all poisons.</p>
    <p><a id="druid-a-thousand-faces"></a><b>A Thousand Faces (Su)</b>: At 13th level, a druid gains the
ability to change her appearance at will, as if using the <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#disguise-self">disguise self</a>
spell, but only while in her normal form. This affects the druid�s body but not her possessions. It is
not an illusory effect, but a minor physical alteration of
the druid�s appearance, within the limits described for
the spell.</p>
    <p><a id="druid-timeless-body"></a><b>Timeless Body (Ex)</b>: After attaining 15th level, a druid
no longer takes ability score penalties for aging and cannot be
magically aged. Any penalties she may have already incurred, however,
remain in place. Bonuses still accrue, and the druid still dies of old
age when her time is up.</p>
    <h6><a id="ex-druids"></a>Ex-Druids</h6>
    <p className="initial">A druid who ceases to revere nature, changes
to a prohibited alignment, or teaches the Druidic language to a
nondruid loses all spells and druid abilities (including her animal
companion, but not including weapon, armor, and shield proficiencies).
She cannot thereafter gain levels as a druid until she atones (see the
atonement spell description).</p>
    <h5><a id="the-druids-animal-companion"></a>THE DRUID&rsquo;S ANIMAL COMPANION</h5>
    <p className="initial">A druid&rsquo;s animal companion is superior to a
normal animal of its kind and has special powers, as described below.</p>
    <table style={{
      "margin": "0.5em 0pt",
      "width": "60%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th><a id="table-the-druids-animal-companion"></a>Class Level</th>
          <th className="center">Bonus HD</th>
          <th className="center">Natural Armor Adj.</th>
          <th className="center">Str/Dex Adj.</th>
          <th className="center">Bonus Tricks</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st&ndash;2nd</td>
          <td align="center">+0</td>
          <td align="center">+0</td>
          <td align="center">+0</td>
          <td align="center">1</td>
          <td>Link, share spells</td>
        </tr>
        <tr>
          <td>3rd&ndash;5th</td>
          <td align="center">+2</td>
          <td align="center">+2</td>
          <td align="center">+1</td>
          <td align="center">2</td>
          <td>Evasion</td>
        </tr>
        <tr className="odd-row">
          <td>6th&ndash;8th</td>
          <td align="center">+4</td>
          <td align="center">+4</td>
          <td align="center">+2</td>
          <td align="center">3</td>
          <td>Devotion</td>
        </tr>
        <tr>
          <td>9th&ndash;11th</td>
          <td align="center">+6</td>
          <td align="center">+6</td>
          <td align="center">+3</td>
          <td align="center">4</td>
          <td>Multiattack</td>
        </tr>
        <tr className="odd-row">
          <td>12th&ndash;14th</td>
          <td align="center">+8</td>
          <td align="center">+8</td>
          <td align="center">+4</td>
          <td align="center">5</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>15th&ndash;17th</td>
          <td align="center">+10</td>
          <td align="center">+10</td>
          <td align="center">+5</td>
          <td align="center">6</td>
          <td>Improved evasion</td>
        </tr>
        <tr className="odd-row">
          <td>18th&ndash;20th</td>
          <td align="center">+12</td>
          <td align="center">+12</td>
          <td align="center">+6</td>
          <td align="center">7</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <p><a id="animal-companion-basics"></a><b>Animal Companion Basics</b>: Use the base statistics for a
creature of the companion&rsquo;s kind, but make the following changes.</p>
    <p><i>Class Level</i>: The character&rsquo;s druid level. The druid&rsquo;s
class levels stack with levels of any other classes that are entitled
to an animal companion for the purpose of determining the companion&rsquo;s
abilities and the alternative lists available to the character.</p>
    <p><a id="animal-companion-bonus-hd"></a><i>Bonus HD</i>: Extra eight-sided (d8) Hit Dice, each of
which gains a Constitution modifier, as normal. Remember that extra Hit
Dice improve the animal companion&rsquo;s base attack and base save bonuses.
An animal companion&rsquo;s base attack bonus is the same as that of a druid
of a level equal to the animal&rsquo;s HD. An animal companion has good
Fortitude and Reflex saves (treat it as a character whose level equals
the animal&rsquo;s HD). An animal companion gains additional skill points and
feats for bonus HD as normal for advancing a monster&rsquo;s Hit Dice.</p>
    <p><i>Natural Armor Adj.</i>: The number noted here is an
improvement to the animal companion&rsquo;s existing natural armor bonus.</p>
    <p><i>Str/Dex Adj.</i>: Add this value to the animal companion&rsquo;s
Strength and Dexterity scores.</p>
    <p><a id="animal-companion-bonus-tricks"></a><i>Bonus Tricks</i>: The value given in this column is the
total number of &ldquo;bonus&rdquo; tricks that the animal knows in addition to any
that the druid might choose to teach it (see the Handle Animal skill).
These bonus tricks don&rsquo;t require any training time or Handle Animal
checks, and they don&rsquo;t count against the normal limit of tricks known
by the animal. The druid selects these bonus tricks, and once selected,
they can&rsquo;t be changed.</p>
    <p><a id="animal-companion-link"></a><i>Link (Ex)</i>: A druid can handle her animal companion as a
free action, or push it as a move action, even if she doesn&rsquo;t have any
ranks in the Handle Animal skill. The druid gains a +4 circumstance
bonus on all wild empathy checks and Handle Animal checks made
regarding an animal companion.</p>
    <p><a id="animal-companion-share-spell"></a><i>Share Spells (Ex)</i>: At the druid&rsquo;s option, she may have
any spell (but not any spell-like ability) she casts upon herself also
affect her animal companion. The animal companion must be within 5 feet
of her at the time of casting to receive the benefit. If the spell or
effect has a duration other than instantaneous, it stops affecting the
animal companion if the companion moves farther than 5 feet away and
will not affect the animal again, even if it returns to the druid
before the duration expires. Additionally, the druid may cast a spell
with a target of &ldquo;You&rdquo; on her animal companion (as a touch range spell)
instead of on herself. A druid and her animal companion can share
spells even if the spells normally do not affect creatures of the
companion&rsquo;s type (animal).</p>
    <p><a id="animal-companion-evasion"></a><i>Evasion (Ex)</i>: If an animal companion is subjected to an
attack that normally allows a Reflex saving throw for half damage, it
takes no damage if it makes a successful saving throw.</p>
    <p><a id="animal-companion-devotion"></a><i>Devotion (Ex)</i>: An animal companion gains a +4 morale
bonus on Will saves against enchantment spells and effects.</p>
    <p><a id="animal-companion-multiattack"></a><i>Multiattack</i>: An animal companion gains Multiattack as a
bonus feat if it has three or more natural attacks and does not already
have that feat. If it does not have the requisite three or more natural
attacks, the animal companion instead gains a second attack with its
primary natural weapon, albeit at a &ndash;5 penalty.</p>
    <p><a id="animal-companion-improved-evasion"></a><i>Improved Evasion (Ex)</i>: When subjected to an attack that
normally allows a Reflex saving throw for half damage, an animal
companion takes no damage if it makes a successful saving throw and
only half damage if the saving throw fails.</p>
    <h5><a id="alternative-animal-companions"></a>ALTERNATIVE ANIMAL COMPANIONS</h5>
    <p className="initial">A druid of sufficiently high level can select
her animal companion from one of the following lists, applying the
indicated adjustment to the druid&rsquo;s level (in parentheses) for purposes
of determining the companion&rsquo;s characteristics and special abilities.</p>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th colSpan="2">4th Level or Higher (Level &ndash;3)</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Ape (animal)</td>
          <td style={{
            "width": "200px"
          }}>Dire weasel</td>
        </tr>
        <tr>
          <td>Bear, black (animal)</td>
          <td>Leopard (animal)</td>
        </tr>
        <tr className="odd-row">
          <td>Bison (animal)</td>
          <td>Lizard, monitor (animal)</td>
        </tr>
        <tr>
          <td>Boar (animal)</td>
          <td>Shark, Large<sup>1</sup> (animal)</td>
        </tr>
        <tr className="odd-row">
          <td>Cheetah (animal)</td>
          <td>Snake, constrictor (animal)</td>
        </tr>
        <tr>
          <td>Crocodile (animal)<sup>1</sup></td>
          <td>Snake, Large viper (animal)</td>
        </tr>
        <tr className="odd-row">
          <td>Dire badger</td>
          <td>Wolverine (animal)</td>
        </tr>
        <tr>
          <td className="last-row">Dire bat</td>
          <td className="last-row">&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th colSpan="2">7th Level or Higher (Level &ndash;6)</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Bear, brown (animal)</td>
          <td style={{
            "width": "200px"
          }}>Dire wolf</td>
        </tr>
        <tr>
          <td>Dire wolverine</td>
          <td>Elasmosaurus<sup>1</sup> (dinosaur)</td>
        </tr>
        <tr className="odd-row">
          <td>Crocodile, giant (animal)</td>
          <td>Lion (animal)</td>
        </tr>
        <tr>
          <td>Deinonychus (dinosaur)</td>
          <td>Rhinoceros (animal)</td>
        </tr>
        <tr className="odd-row">
          <td>Dire ape</td>
          <td>Snake, Huge viper (animal)</td>
        </tr>
        <tr>
          <td className="last-row">Dire boar</td>
          <td className="last-row">Tiger (animal)</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th colSpan="2">10th Level or Higher (Level &ndash;9)</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Bear, polar (animal)</td>
          <td style={{
            "width": "200px"
          }}>Shark, Huge<sup>1</sup> (animal)</td>
        </tr>
        <tr>
          <td>Dire lion</td>
          <td>Snake, giant constrictor (animal)</td>
        </tr>
        <tr className="odd-row">
          <td>Megaraptor (dinosaur)</td>
          <td>Whale, orca<sup>1</sup> (animal)</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th colSpan="2">13th Level or Higher (Level &ndash;12)</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Dire bear</td>
          <td style={{
            "width": "200px"
          }}>Octopus, giant<sup>1</sup> (animal)</td>
        </tr>
        <tr>
          <td className="last-row">Elephant (animal)</td>
          <td className="last-row">&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th colSpan="2">16th Level or Higher (Level &ndash;15)</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Dire shark<sup>1</sup></td>
          <td style={{
            "width": "200px"
          }}>Triceratops (dinosaur)</td>
        </tr>
        <tr>
          <td>Dire tiger</td>
          <td>Tyrannosaurus (dinosaur)</td>
        </tr>
        <tr className="odd-row">
          <td>Squid, giant<sup>1</sup> (animal)</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <p>1 <i>Available only in an aquatic environment.</i></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      